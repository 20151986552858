<template>
  <b-overlay
    :show="show"
    variant="transparent"
    opacity="0.99"
    blur="5px"
    rounded="sm"
  >
    <template #overlay>
      <div class="text-center">
        <b-icon-controller
          font-scale="3"
          animation="cylon"
        />
        <p id="cancel-label">
          กรุณารอสักครู่...
        </p>
      </div>
    </template>
    <div class="card">
      <div class="card-body">
        <div>
          <h2 class="text-dark font-medium-3">
            <i class="fa fa-search" /> ค้นหา
          </h2>
        </div>
        <div class="row">
          <div class="col-md-3">
            <div class="form-group">
              <label for="username">ประเภทที่จะค้นหา</label>
              <b-form-select
                v-model="search_type"
                :options="search_type_option"
                placeholder="เลือกประเภทที่จะค้นหา"
              />
            </div>
          </div>
          <div

            class="col-md-3"
          >
            <div
              class="form-group"
            >
              <label for="username">Keyword</label>
              <input
                id="username"
                v-model="search_val"
                :disabled="!search_type"
                type="text"
                class="form-control"
                placeholder="กรอกข้อมูลที่ต้องการค้นหา"
                :maxlength="getMaxLength()"
              >
            </div>
          </div>
          <div class="col-md-3 mt-2">
            <button
              class="btn btn-gradient-dark"
              @click="getHistory()"
            >
              <feather-icon icon="SearchIcon" />
              ค้นหา
            </button>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <!-- <label for="dateStart">วันที่เริ่มต้น</label>
              <flat-pickr
                v-model="dateStart"
                class="form-control"
                placeholder="เลือกเวลาเริ่มต้น"
              /> -->
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <!-- <label for="dateEnd">วันที่สิ้นสุด</label>
              <flat-pickr
                v-model="dateEnd"
                class="form-control"
                placeholder="เลือกเวลาสิ้นสุด"
              /> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-card
      no-body
    >
      <b-card-body>
        <div class="d-flex justify-content-between flex-wrap">
          <div
            style="display: flex"
          >
            <!-- <b-button
              v-b-modal.modal-1
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="gradient-primary"
              class="mr-1"
              @click="search = false, tel = null , member = null"
            >
              ทำรายการโยกเงิน
            </b-button>
          </div> -->
          </div>
        </div></b-card-body>
      <b-table
        small
        striped
        hover
        responsive
        show-empty
        class="position-relative items-center"
        :per-page="perPage"
        :items="items"
        :fields="fields"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        :filter="filter"
        :filter-included-fields="filterOn"
      >
        <template #cell(index)="data">
          {{ perPage * (currentPage - 1) + (data.index + 1) }}
        </template>

        <template #cell(before_balance)="data">
          <p class="font-weight-bolder text-warning mb-0">
            {{ data.item.before_balance ? Commas(data.item.before_balance.toFixed(2)) : 0.00 }}
          </p>
        </template>

        <template #cell(actions)="data">
          <b-form-checkbox
            v-model="data.item.type1"
            class="custom-control-info"
            name="check-button"
            switch
            @change="add(data.item._id,data.item.type1,1)"
          >
            <span class="switch-icon-left">
              <feather-icon icon="CheckIcon" />
            </span>
            <span class="switch-icon-right">
              <feather-icon icon="XIcon" />
            </span>
          </b-form-checkbox>
        </template>
        <template #cell(actions2)="data">
          <b-form-checkbox
            v-model="data.item.type2"
            class="custom-control-info"
            name="check-button"
            switch
            @change="add(data.item._id,data.item.type2,2)"
          >
            <span class="switch-icon-left">
              <feather-icon icon="CheckIcon" />
            </span>
            <span class="switch-icon-right">
              <feather-icon icon="XIcon" />
            </span>
          </b-form-checkbox>
        </template>
        <template #cell(actions3)="data">
          <b-form-checkbox
            v-model="data.item.type3"
            class="custom-control-primary"
            name="check-button"
            switch
            @change="add(data.item._id,data.item.type3,3)"
          >
            <span class="switch-icon-left">
              <feather-icon icon="CheckIcon" />
            </span>
            <span class="switch-icon-right">
              <feather-icon icon="XIcon" />
            </span>
          </b-form-checkbox>
        </template>
        <template #cell(actions4)="data">
          <b-form-checkbox
            v-model="data.item.type4"
            class="custom-control-success"
            name="check-button"
            switch
            @change="add(data.item._id,data.item.type4,4)"
          >
            <span class="switch-icon-left">
              <feather-icon icon="CheckIcon" />
            </span>
            <span class="switch-icon-right">
              <feather-icon icon="XIcon" />
            </span>
          </b-form-checkbox>
        </template>
      </b-table>

      <b-card-body class="d-flex justify-content-between flex-wrap pt-0">

        <!-- page length -->
        <b-form-group
          label="Per Page"
          label-cols="8"
          label-align="left"
          label-size="sm"
          label-for="sortBySelect"
          class="text-nowrap mb-md-0 mr-1"
        >
          <b-form-select
            id="perPageSelect"
            v-model="perPage"
            size="sm"
            inline
            :options="pageOptions"
            @change="getHistory()"
          />
        </b-form-group>

        <!-- pagination -->
        <div>
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            aria-controls="my-table"
            prev-class="prev-item"
            next-class="next-item"
            class="mb-0"
            @input="getHistory()"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
                @click="currentPage = currentPage - 10"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
                @click="currentPage = currentPage + 10"
              />
            </template>
          </b-pagination>
        </div>
      </b-card-body>
      <b-modal
        id="modal-1"
        title="โยกเงิน"
        ok-title="ยืนยัน"
        @ok="ConfirmSubmit()"
      >
        <!-- <div class="mb-2">
          <label for="favorite">รายการโปรด</label>

          <div class="d-flex">
            <div
              v-for="item in favorite"
              :key="item.id"
              class="text-center"
            >
              <div class="favrt">
                <img
                  src="/bankIcon/KBANK.png"
                  alt="bank-form"
                  class="img-fluid"
                >
              </div>
              <p class="mb-0">
                {{ item.name }}
              </p>
            </div>

            <div class="text-center">
              <div
                v-b-modal.modal-add
                class="favrt-add"
              >
                <i class="fa fa-plus fa-2x" />
              </div>
            </div>
          </div>
        </div> -->
        <b-form-group>
          <label for="web">บัญชีที่ต้องการถอน</label>
          <b-form-select
            v-model="acc_data"
            :options="ListAcc"
            value-field="ID"
            text-field="displayname"
            placeholder="เลือกบัญชีที่ต้องการถอน"
          />
        </b-form-group>
        <b-form-group
          label="จำนวนเงินที่ถอน"
          label-for="vi-first-name"
        >
          <b-form-input
            id="vi-first-name"
            v-model="amount"
            placeholder="0"
          />
        </b-form-group>
        <b-form-group>
          <label for="web">ถอนไปยังธนาคาร</label>
          <b-form-select
            v-model="bank"
            :options="BankList"
            value-field="bankcode"
            text-field="name"
            placeholder="เลือกธนาคาร"
          />
        </b-form-group>
        <b-form-group
          label="ถอนไปยังเลขที่บัญชี (ไม่ต้องใส่ -)"
          label-for="vi-first-acc"
        >
          <b-form-input
            id="vi-first-acc"
            v-model="acc_no"
            placeholder=""
          />
        </b-form-group>
      </b-modal>

      <b-modal
        id="modal-add"
        title="เพิ่มรายการโปรด"
        ok-title="ยืนยัน"
        cancel-title="ยกเลิก"
        @ok="addFavorite"
      >
        <b-form-group
          label="ชื่อรายการ"
          label-for="vi-first-acc"
        >
          <b-form-input
            id="vi-first-acc"
            v-model="favoriteName"
            placeholder=""
          />
        </b-form-group>

        <b-form-group>
          <label for="web">บัญชีที่ต้องการถอน</label>
          <b-form-select
            v-model="acc_data"
            :options="ListAcc"
            value-field="ID"
            text-field="displayname"
            placeholder="เลือกบัญชีที่ต้องการถอน"
          />
        </b-form-group>

        <b-form-group>
          <label for="web">ถอนไปยังธนาคาร</label>
          <b-form-select
            v-model="bank"
            :options="BankList"
            value-field="bankcode"
            text-field="name"
            placeholder="เลือกธนาคาร"
          />
        </b-form-group>
        <b-form-group
          label="ถอนไปยังเลขที่บัญชี (ไม่ต้องใส่ -)"
          label-for="vi-first-acc"
        >
          <b-form-input
            id="vi-first-acc"
            v-model="acc_no"
            placeholder=""
          />
        </b-form-group>

        <hr>

        <div>
          <label for="favorite">เลือกแก้ไขรายการ</label>

          <div class="d-flex">
            <div
              v-for="item in favorite"
              :key="item.id"
              class="text-center txtn"
              @click="Selected = item"
            >
              <div class="favrt">
                <img
                  src="/bankIcon/KBANK.png"
                  alt="bank-form"
                  class="img-fluid"
                >
              </div>
              <p class="mb-0">
                {{ item.name }}
              </p>

              <div
                class="closed-x"
                @click="deleteFav()"
              >
                <i class="fa fa-times" />
              </div>
            </div>
          </div>
        </div>
      </b-modal>
    </b-card>
  </b-overlay>
</template>

<script>
// import vSelect from 'vue-select'
import moment from 'moment-timezone'
// import flatPickr from 'vue-flatpickr-component'
import {
  // BRow,
  // BCol,
  // BAvatar,
  // , BBadge,
  // , BButton
  BFormCheckbox,
  VBTooltip, BModal, BCard, BTable, BFormGroup, BFormSelect, BPagination, BFormInput, BCardBody, VBToggle, BOverlay, BIconController,
  // BFormRadio,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
// import flatPickr from 'vue-flatpickr-component'

export default {
  components: {
    // flatPickr,
    // BRow,
    // BCol,
    // vSelect,
    BModal,
    BCard,
    BTable,
    // BAvatar,
    BFormGroup,
    BFormSelect,
    BPagination,
    BFormInput,
    // BButton,
    BCardBody,
    BOverlay,
    BIconController,
    BFormCheckbox,
    // BBadge,
    // BFormRadio,
    // flatPickr,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      favorite: [
        { id: 1, name: 'test1' },
        { id: 2, name: 'test2' },
        { id: 3, name: 'test3' },
        { id: 4, name: 'test4' },
        { id: 5, name: 'test5' },
      ],
      search_type_option: [
        { value: 1, text: 'เลข 6 ตัว' },
        { value: 2, text: 'เลข 3 ตัวหน้า' },
        { value: 3, text: 'เลข 3 ตัวท้าย' },
        { value: 4, text: 'เลข 2 ตัวท้าย' },
      ],
      search_type: null,
      search_val: null,
      show: false,
      show2: false,
      Selected: null,
      depositdata: [],
      member: '',
      optionsMember: '',
      perPage: 10,
      pageOptions: [10, 20, 50, 100, 200, 500],
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      usersList: [],
      datasidebar: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      fields: [
        { key: 'index', label: 'no.', thStyle: { width: '10%' } },
        { key: 'lottonumber', label: 'เลขสลาก', thStyle: { width: '15%' } },
        { key: 'roundth', label: 'งวดวันที่', thStyle: { width: '20%' } },
        { key: 'edit_type_by', label: 'จัดโดย', thStyle: { width: '15%' } },
        { key: 'actions2', label: 'เลขเด็ด 3 ตัวหน้า', thStyle: { width: '10%' } },
        { key: 'actions3', label: 'เลขเด็ด 3 ตัวท้าย', thStyle: { width: '10%' } },
        { key: 'actions4', label: 'เลขเด็ด 2 ตัวท้าย', thStyle: { width: '10%' } },
      ],
      /* eslint-disable global-require */
      items: [],
      addNewDataSidebar: false,
      UserData: JSON.parse(localStorage.getItem('userData')),
      tel: null,
      search: false,
      dateStart: moment().tz('Asia/Seoul').format('YYYY-MM-DD'),
      dateEnd: moment().tz('Asia/Seoul').format('YYYY-MM-DD'),
      ListAcc: [],
      BankList: [],
      acc_data: null,
      acc_no: null,
      bank: null,
      amount: null,
    }
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  // created() {
  //   this.interval = setInterval(() => {
  //     this.getHistory()
  //   }, 60000)
  // },
  // destroyed() {
  //   clearInterval(this.interval)
  // },
  mounted() {
    // this.totalRows = this.items.length
    this.getHistory()
  },
  methods: {
    add(id, status, type) {
      // console.log(status)
      if (status) {
        // eslint-disable-next-line block-scoped-var, vars-on-top, no-var
        var types = type
      } else {
        // eslint-disable-next-line vars-on-top, no-var, no-redeclare, block-scoped-var
        var types = 0
      }
      const params = {
        id,
        // eslint-disable-next-line block-scoped-var
        type: types,
      }
      this.$http
        .get('/lottery/ChangeLuckyNumber', { params })
        // eslint-disable-next-line no-unused-vars
        .then(response => {
          this.getHistory(1)
        })
        .catch(error => console.log(error))
    },
    getMaxLength() {
      const selectedOption = this.search_type_option.find(option => option.value === this.search_type)
      if (selectedOption) {
        switch (selectedOption.value) {
          case 1:
            return 6
          case 2:
          case 3:
            return 3
          case 4:
            return 2
          default:
            return Infinity // ค่าเริ่มต้นสำหรับกรณีไม่ตรงกับเงื่อนไขใด ๆ
        }
      }
      return Infinity // หรือคืนค่าเริ่มต้นเมื่อไม่พบค่า search_type
    },
    async getHistory(skip) {
      if (skip) {
        this.show = false
      } else {
        this.show = true
      }
      const params = {
        page: this.currentPage,
        perPage: this.perPage,
        search_val: this.search_val,
        search_type: this.search_type,
        dateStart: this.dateStart,
        dateEnd: this.dateEnd,
      }
      this.$http
        .get('/lottery/GetLuckyNumber', { params })
        .then(response => {
          this.show = false
          this.onFiltered(response.data)
        })
        .catch(error => console.log(error))
    },
    Commas(x) {
      if (!x) {
        return 0
      } if (x % 1 !== 0) {
        return Number(x).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      }
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
    onFiltered(filteredItems) {
      // สร้าง loop เช็ค ถ้า type = 1 ให้เปลี่ยน type เป็น true ถ้า type = 0 ให้เปลี่ยน type เป็น false
      filteredItems.data.forEach(item => {
        if (item.type === 1) {
          // eslint-disable-next-line no-param-reassign
          item.type1 = true
        } else {
          // eslint-disable-next-line no-param-reassign
          item.type1 = false
        }
        if (item.type === 2) {
          // eslint-disable-next-line no-param-reassign
          item.type2 = true
        } else {
          // eslint-disable-next-line no-param-reassign
          item.type2 = false
        }
        if (item.type === 3) {
          // eslint-disable-next-line no-param-reassign
          item.type3 = true
        } else {
          // eslint-disable-next-line no-param-reassign
          item.type3 = false
        }
        if (item.type === 4) {
          // eslint-disable-next-line no-param-reassign
          item.type4 = true
        } else {
          // eslint-disable-next-line no-param-reassign
          item.type4 = false
        }
      })
      // console.log(filteredItems.data)
      this.items = filteredItems.data
      this.totalRows = filteredItems.total
    },
    Success(mes) {
      this.$swal({
        icon: 'success',
        title: '<h3 style="color: #141414">ทำรายการสำเร็จ</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-success',
        },
      })
    },
    SwalError(mes) {
      this.$swal({
        icon: 'error',
        title: '<h3 style="color: #141414">เกิดข้อผิดพลาด!</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      })
    },
  },
}
</script>
<style scoped>
.favrt {
  margin: 0 10px;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  background: #fff;
  border: 3px solid #efceff8c;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  cursor: pointer;
}

.favrt-add {
  margin: 0 10px;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  background: #f8f0fc;
  border: 3px dotted #e4a2ec8c;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  cursor: pointer;
}
.favrt-add i {
  color: #9191918e;
}

.txtn {
  position: relative;
}
.txtn .closed-x {
  position: absolute;
  top: -5px;
  right: 5px;
  background: #fc0202;
  color: #fff;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
  .bank-icon {
    width: 35px;
    height: 35px;
    padding: 5px;
    border-radius: 50%;
  }
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
@import '@core/scss/vue/libs/vue-flatpicker.scss';
.form-item-section {
background-color:$product-details-bg;
}

</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
